import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"

const AblaufPage = () => (
  <Layout>
    <SEO title="Ablauf" />
    <Section>
      <Container>
        <H1>Ablauf</H1>
        <p>Wir begleiten Sie auf Ihrer Reise ins Web!</p>
      </Container>
    </Section>
  </Layout>
)

export default AblaufPage
